import { AccountInfo, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { getCurrentInstance, Ref, toRefs } from "vue";

export type MsalContext = {
  /**PublicClientApplication instance of Msal*/
  instanceMsal: PublicClientApplication;
  /**array of accounts currently signed in*/
  accounts: Ref<AccountInfo[]>;
  /**indicating what type of interaction is currently in progress*/
  inProgress: Ref<InteractionStatus>;
};

/**
 * When we want to use the instance of Msal (e.g. login, logout etc.) we should use this function.
 * Can be called from the setup function from any of Vue components that need to do something authentication related.
 * @returns MsalContext
 *  - `instanceMsal` - PublicClientApplication instance of Msal
 *  - `accounts` - array of accounts currently signed in
 *  - `inProgress` - indicating what type of interaction is currently in progress
 */
export function useMsal(): MsalContext {
  const internalInstance = getCurrentInstance();
  if (!internalInstance) {
    throw "useMsal() cannot be called outside the setup() function of a component";
  }
  const { instance, accounts, inProgress } = toRefs(internalInstance.appContext.config.globalProperties.$msal);

  if (!instance || !accounts || !inProgress) {
    throw "Please install the msalPlugin";
  }

  if (inProgress.value === InteractionStatus.Startup) {
    instance.value.handleRedirectPromise().catch(() => {
      // Errors should be handled by listening to the LOGIN_FAILURE event
      return;
    });
  }

  return {
    instanceMsal: instance.value,
    accounts,
    inProgress,
  };
}
