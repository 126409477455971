export enum EnvelopeStatus {
  None = 0,
  Error = 1,
  NotFound = 2,
  Completed = 3,
  Created = 4,
  Declined = 5,
  Delivered = 6,
  Sent = 7,
  Signed = 8,
  Voided = 9,
}

/*

*/
