import { DisplayBreakpoint, DisplayThresholds, ThemeDefinition } from "vuetify";

// NOTE: not currently exported by vuetify.
// This is a work around.
interface DisplayOptions {
  mobileBreakpoint?: number | DisplayBreakpoint;
  thresholds?: Partial<DisplayThresholds>;
}

export const display: DisplayOptions = {
  mobileBreakpoint: "sm",
  //   thresholds: {
  //     xs: 320,
  //     sm: 400,
  //     md: 540,
  //     lg: 800,
  //     xl: 1280,
  //   },
};

export const cmpDefaultTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#D8D8D8",
    primary: "#D8D8D8",
    surface: "#FFFFFF",
    secondary: "#03DAC6",
    "primary-btn": "#3e9c40",
    "secondary-tertiary-btn": "#D8D8D8",
    "secondary-on-tertiary-btn": "#4A4A4A",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

export const cmpAuthPageTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    primary: "#D8D8D8",
    surface: "#FFFFFF",
    secondary: "#03DAC6",
    "primary-btn": "#3e9c40",
    "secondary-tertiary-btn": "#D8D8D8",
    "secondary-on-tertiary-btn": "#4A4A4A",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};
