import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from "axios";

import { eActions, store } from "@/store";
import { EnvOptions } from "@/settings/envOptions";

// eslint-disable-next-line prettier/prettier
import { upperCaseFirst } from "upper-case-first";

/**
 * axios request interceptor/middleware used to apply common request configuration to axios instance
 * in addition to defaults.
 * @param config
 */
function axiosRequestCommonConfig(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  //https://learn.microsoft.com/en-us/azure/active-directory/develop/security-tokens
  //config.headers.Authorization = `Bearer ${store.state.accessToken}`; //retaining for reference until mvp complete.
  // we will need accessToken for profile(graph api). we can get it if modify loginReq and call acquireToken
  // in future we should use line below with getting accessToken
  // const token = store.state.accessToken === "" ? store.state.idToken : store.state.accessToken;
  const token = store.state.idToken;
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Accept = "application/json";
  config.headers.setContentType("application/json");

  return config;
}

//singleton instance.
let axiosCMPBackendClient: AxiosInstance;
let axiosCMPBackendPascalCaseClient: AxiosInstance;

/**
 * creates a singleton axios instance used to comm with cmpbackend.
 */
export function createAxiosCMP() {
  if (axiosCMPBackendClient) {
    return axiosCMPBackendClient;
  }
  return createAxiosCMPWithAxiosInstance(axiosCMPBackendClient);
}

export function createAxiosCMPPascalCase() {
  if (axiosCMPBackendPascalCaseClient) return axiosCMPBackendPascalCaseClient;

  axiosCMPBackendPascalCaseClient = createAxiosCMPWithAxiosInstance(axiosCMPBackendPascalCaseClient);

  axiosCMPBackendPascalCaseClient.defaults.transformResponse = [
    function (data) {
      const parsedData = JSON.parse(data);
      return convertToFirstLetterCapitalCase(parsedData);
    },
  ];

  return axiosCMPBackendPascalCaseClient;
}

//JoGo 4/21/23 - code copied from
//https://desco.visualstudio.com/FieldEdge/_git/FieldEdge.Typescript.Rest?path=/src/models/base/RestClientBase.ts
function convertToFirstLetterCapitalCase(data: any): any {
  if (typeof data !== "object" || data === null) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(convertToFirstLetterCapitalCase);
  }

  return Object.keys(data).reduce((result, key) => {
    const value = data[key];
    const capitalCaseKey = upperCaseFirst(key);
    (result as any)[capitalCaseKey] = convertToFirstLetterCapitalCase(value);
    return result;
  }, {});
}

export function createAxiosCMPWithAxiosInstance(AxiosInstance: AxiosInstance) {
  if (AxiosInstance) {
    return AxiosInstance;
  }

  AxiosInstance = axios.create({
    withCredentials: false,
    //todo: consider realigning with devops and see if we can factor out this `/api` by setting at the deployment config level into VUE_APP_SERVER_URL
    baseURL: `${EnvOptions.ServerUrl}/api`,
  });

  AxiosInstance.interceptors.request.use(axiosRequestCommonConfig, (e) => Promise.reject(e));
  AxiosInstance.interceptors.response.use(
    (response) => response,
    (e: AxiosError) => {
      if (e.response?.status === 401) {
        //todo: we'll probably want to make the service implementations responsible for determining if
        //auth login should be triggered.
        //Performing it here is for concept testing. This will cause issues with services being able to
        //direct users in an expected way if there is an issue. eg, maybe display an alert, wait, trigger auth.
        //Currently auth will likely usurp the UI flow.
        //
        //attempt to fix auth, the system will attempt a silent re-auth 1st.
        store.dispatch(eActions.setTriggerAuth, true);
      }

      return Promise.reject(e);
    }
  );

  return AxiosInstance;
}
