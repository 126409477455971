// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { md2 } from "vuetify/blueprints";

// Vuetify
import { createVuetify } from "vuetify";
import { cmpAuthPageTheme, cmpDefaultTheme, display } from "@/settings/vuetifySettings";

export default createVuetify({
  blueprint: md2,
  theme: {
    defaultTheme: "cmpDefaultTheme",
    themes: {
      cmpDefaultTheme,
      cmpAuthPageTheme,
    },
  },
  display: display,
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
