import { store } from "@/store";
import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { Ref, ref, watch } from "vue";
import { useMsal } from "./useMsal";

export type MsalAuthenticationResult = {
  /**function that can be called from your Vue component when a new token is needed or to recover from an error*/
  acquireToken: () => void;
  /**AuthenticationResult - containing the accessToken and idToken*/
  result: Ref<AuthenticationResult | null>;
  /** object if the token request failed for any reason*/
  error: Ref<AuthError | null>;
  /**indicating if this instance of useMsalAuthentication is currently acquiring a token(this is different from the inProgress value returned by useMsal)*/
  inProgress: Ref<boolean>;
  accounts: Ref<AccountInfo[]>;
};

/**
 * Will attempt to silently acquire a token and fallback to interaction if no user is signed in or silent acquisition fails.
 * This can be used instead of a route guard to protect components.
 *
 * @param interactionType
 * @param request
 * @returns MsalAuthenticationResult
 * - `result` - AuthenticationResult - containing the accessToken and idToken
 * - `acquireToken` - function that can be called from your Vue component when a new token is needed or to recover from an error
 * - `error` -  object if the token request failed for any reason
 * - `inProgress` - indicating if this instance of useMsalAuthentication is currently acquiring a token(this is different from the inProgress value returned by useMsal)
 */
export function useMsalAuthentication(
  interactionType: InteractionType,
  request: PopupRequest | RedirectRequest | SilentRequest
): MsalAuthenticationResult {
  const { instanceMsal, inProgress, accounts } = useMsal();
  const localInProgress = ref<boolean>(false);
  const result = ref<AuthenticationResult | null>(null);
  const error = ref<AuthError | null>(null);

  const acquireToken = async (requestOverride?: PopupRequest | RedirectRequest | SilentRequest) => {
    if (!localInProgress.value) {
      localInProgress.value = true;
      const tokenRequest = requestOverride || request;

      if (inProgress.value === InteractionStatus.Startup || inProgress.value === InteractionStatus.HandleRedirect) {
        try {
          const response = await instanceMsal.handleRedirectPromise();
          if (response) {
            result.value = response;
            error.value = null;
            localInProgress.value = false;
            return;
          }
        } catch (e) {
          result.value = null;
          error.value = e as AuthError;
          return;
        } finally {
          localInProgress.value = false;
        }
      }

      try {
        const response = await instanceMsal.acquireTokenSilent(tokenRequest);
        localInProgress.value = false;
        result.value = response;
        error.value = null;
      } catch (e) {
        if (inProgress.value !== InteractionStatus.None) {
          return;
        }

        if (interactionType === InteractionType.Popup) {
          instanceMsal
            .loginPopup(tokenRequest)
            .then((response) => {
              localInProgress.value = false;
              result.value = response;
              error.value = null;
            })
            .catch((e) => {
              localInProgress.value = false;
              error.value = e;
              result.value = null;
            });
        } else if (interactionType === InteractionType.Redirect) {
          await instanceMsal.loginRedirect(tokenRequest).catch((e) => {
            localInProgress.value = false;
            error.value = e;
            result.value = null;
          });
        }
      }
    }
  };

  const stopWatcher = watch(inProgress, () => {
    if (!result.value && !error.value) {
      acquireToken();
    } else {
      stopWatcher();
    }
  });

  return {
    acquireToken,
    result,
    error,
    inProgress: localInProgress,
    accounts,
  };
}
