import {
  IAccountB2C,
  ICompanyCustomerPair,
  IPropertiesInfoResponse,
  IPropertyData,
  ISelectedCompanyAccountData,
} from "@/lib/shared-definitions";
import { createAxiosCMP } from "@/lib/axios/cmpbackend";
import { AllDisplayProperty } from "@/lib/shared-constants";

export default class AccountService {
  static isAllProperty(prop: IPropertyData) {
    return prop.entityId === AllDisplayProperty.entityId;
  }

  async getAccountInfo(accountId: string) {
    const ax = createAxiosCMP();
    return (await ax.get<IAccountB2C>(`/user-info/${accountId}`)).data;
  }

  async postAccountInfo(accountB2C: IAccountB2C) {
    const ax = createAxiosCMP();
    return (await ax.post<IAccountB2C>(`/user-info`, accountB2C)).data;
  }

  async putAccountEmail(accountId: string, accountEmail: string) {
    const ax = createAxiosCMP();
    return (await ax.put<IAccountB2C>(`/user-email/${accountId}/email/${accountEmail}`)).data;
  }

  async getCompanyInfo(companyId: string | unknown, customerId: string | unknown) {
    const ax = createAxiosCMP();
    return (await ax.get<ICompanyCustomerPair>(`/company-info/${companyId}/customer/${customerId}`)).data;
  }

  async getCompaniesByAccount(accountId: string | unknown) {
    // if (EnvOptions.EnableTestData) {
    //   return sampleSelectCompanies;
    // }
    const ax = createAxiosCMP();
    const data = (await ax.get<ISelectedCompanyAccountData[]>(`/companies-info/${accountId}`)).data;
    return data;
  }

  async getPropertiesInfo(companyId: string | unknown, customerId: string | unknown) {
    const ax = createAxiosCMP();
    const properties = (await ax.get<IPropertiesInfoResponse>(`/properties-info/${customerId}/company/${companyId}`))
      .data.properties;

    //Note: for UI specific functionality, if we have more than 1 property we want the special "All Properties" property item to be available.
    //This is use in various areas were a property can be selected or all.
    if (properties.length > 1) {
      properties.splice(0, 0, AllDisplayProperty);
    }
    return properties;
  }
}
