/**
 * Used to add an abstraction layer between the App and environment variables.
 * This allows us to apply any adjustment code needed and gives us some author time type checking.
 */
export class EnvOptions {
  static get ServerUrl(): string {
    return process.env.VUE_APP_SERVER_URL;
  }
  static get B2cClientId(): string {
    return process.env.VUE_APP_B2C_CLIENT_ID;
  }
  static get B2cLoginAuthority(): string {
    return process.env.VUE_APP_B2C_LOGIN_AUTHORITY;
  }
  static get B2cPasswordChangeAuthority(): string {
    return process.env.VUE_APP_B2C_PASSWORD_CHANGE_AUTHORITY;
  }

  static get B2cEmailChangeAuthority(): string {
    return process.env.VUE_APP_B2C_EMAIL_CHANGE_AUTHORITY;
  }

  static get B2cRedirectUri(): string {
    return process.env.VUE_APP_B2C_REDIRECT_URI;
  }
  static get B2cAccessApi(): string {
    return process.env.VUE_APP_B2C_ACCESS_API;
  }
  static get B2cKnownAuthority(): string {
    return process.env.VUE_APP_B2C_KNOWN_AUTHORITY;
  }

  static get EnableTestData(): boolean {
    return process.env.VUE_APP_ENABLE_TESTDATA?.toLowerCase() == "true";
  }

  static get TestAlerts(): boolean {
    return process.env.VUE_APP_TEST_ALERTS?.toLowerCase() == "true";
  }
}
