import { Module } from "vuex";
import { IState } from "..";
import * as WorkOrderService from "@/services/WorkOrderService";
import * as ElectronicsSignatureService from "@/services/ElectronicsSignatureService";
import * as PaymentGatewayService from "@/services/PaymentGatewayService";
import { getConsumerPortalLinkInfoData } from "@/services/WorkFlowService";

import {
  ElectronicSignatureViewModel,
  ConsumerPortalLinkInfoViewModel,
  PaymentInfoModel,
  eWorkflowType,
  BalanceAmounts,
  FieldEdgePayment,
  PaymentInfoRequest,
} from "@/lib/models/WorkflowViewModels";
import { PaymentGatewayCapabilitiesModel } from "fieldedge-typescript-models-core/models/consumerManagementPortal/PaymentGatewayCapabilitiesModel";
import { QuoteModel, WorkOrderModel, eQuoteStatus } from "fieldedge-typescript-models-core";
import { QuoteOptionModel } from "fieldedge-typescript-models-core/models/QuoteModel";

export const getDefaultState = () => ({
  workOrderId: "",
  companyId: "",
  depositPercent: 0,
  fieldEdgePayments: [],
  workflowType: eWorkflowType.None,
  balanceAmounts: new BalanceAmounts(),
  workOrder: new WorkOrderModel(),
  originalWorkOrder: new WorkOrderModel(),
  paymentInfo: new PaymentInfoModel(),
  paymentSettings: new PaymentGatewayCapabilitiesModel(),
  electronicSignature: new ElectronicSignatureViewModel(),
  consumerPortalLinkInfo: new ConsumerPortalLinkInfoViewModel("", ""),
  originalConsumerPortalLinkInfo: new ConsumerPortalLinkInfoViewModel("", ""),
  isPayLater: false,
});

export interface IWorkflowState {
  workOrderId: string;
  companyId: string;
  depositPercent: number;
  workflowType: eWorkflowType;
  balanceAmounts: BalanceAmounts;
  workOrder: WorkOrderModel;
  originalWorkOrder: WorkOrderModel;
  fieldEdgePayments: Array<FieldEdgePayment>;
  paymentInfo: PaymentInfoModel;
  paymentSettings: PaymentGatewayCapabilitiesModel;
  consumerPortalLinkInfo: ConsumerPortalLinkInfoViewModel;
  originalConsumerPortalLinkInfo: ConsumerPortalLinkInfoViewModel;
  electronicSignature: ElectronicSignatureViewModel;
  isPayLater: boolean;
}

export const WorkflowModule: Module<IWorkflowState, IState> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setWorkOrderId(state, val: string) {
      state.workOrderId = val;
    },
    setCompanyId(state, val: string) {
      state.companyId = val;
    },
    setDepositPercent(state, val: number) {
      state.depositPercent = val;
    },
    setOriginalConsumerPortalLink(state, val: ConsumerPortalLinkInfoViewModel) {
      state.originalConsumerPortalLinkInfo = val;
    },
    setWorkOrder(state, val: WorkOrderModel) {
      state.workOrder = val;
    },
    setOriginalWorkOrder(state, val: WorkOrderModel) {
      state.originalWorkOrder = val;
    },
    setWorkflowType(state, val: eWorkflowType) {
      state.workflowType = val;
    },
    setFieldEdgePayments(state, val: Array<FieldEdgePayment>) {
      state.fieldEdgePayments = val;
    },
    setConsumerPortalLinkInfo(state, val: ConsumerPortalLinkInfoViewModel) {
      state.consumerPortalLinkInfo = val;
    },
    setElectronicSignature(state, val: ElectronicSignatureViewModel) {
      state.electronicSignature = val;
    },
    setPaymentInfo(state, val: PaymentInfoModel) {
      state.paymentInfo = val;
    },
    setPaymentSettings(state, val: PaymentGatewayCapabilitiesModel) {
      state.paymentSettings = val;
    },
    setBalanceAmounts(state, val: BalanceAmounts) {
      state.balanceAmounts = val;
    },
    setPayLater(state, val: boolean) {
      state.isPayLater = val;
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setWorkOrderId({ commit }, val: string) {
      commit("setWorkOrderId", val);
    },
    setCompanyId({ commit }, val: string) {
      commit("setCompanyId", val);
    },
    setDepositPercent({ commit }, val: number) {
      commit("setDepositPercent", val);
    },
    setConsumerPortalLinkInfo({ commit }, val: ConsumerPortalLinkInfoViewModel) {
      commit("setConsumerPortalLinkInfo", val);
      commit("setOriginalConsumerPortalLink", JSON.parse(JSON.stringify(val)));
    },
    setBalanceAmounts({ commit }, val: BalanceAmounts) {
      commit("setBalanceAmounts", val);
    },
    setWorkOrder({ commit }, val: WorkOrderModel) {
      commit("setWorkOrder", val);
    },
    setOriginalWorkOrder({ commit, state }) {
      commit("setOriginalWorkOrder", JSON.parse(JSON.stringify(state.workOrder)));
    },
    setPayLater({ commit }, val: boolean) {
      commit("setPayLater", val);
    },
    resetState({ commit }) {
      commit("resetState");
    },
    async loadBalanceAmounts({ commit, state }) {
      await WorkOrderService.getBalanceAmounts(state.workOrder, state.depositPercent).then((result) => {
        commit("setBalanceAmounts", result);
      });
    },
    async loadWorkOrderInfo({ commit, dispatch, state, getters }) {
      await WorkOrderService.getWorkOrderInfoAsync(state.workOrderId, state.companyId).then((result) => {
        commit("setWorkOrder", result.WorkOrder);
        commit("setWorkflowType", result.WorkflowType);
        commit("setFieldEdgePayments", result.FieldEdgePayments);
        dispatch("loadBalanceAmounts");
        if (getters.quoteAcceptedOrScheduled(result.WorkOrder.Quote)) {
          state.consumerPortalLinkInfo.quoteAccepted = true;
          state.consumerPortalLinkInfo.quoteToAccept = result.WorkOrder.Quote as any;
        } else if (result.WorkOrder.Quote.Status == eQuoteStatus.Rejected) {
          state.consumerPortalLinkInfo.quoteDeclined = true;
        } else {
          state.consumerPortalLinkInfo.quoteAccepted = false;
          state.consumerPortalLinkInfo.quoteDeclined = false;
          state.consumerPortalLinkInfo.quoteToAccept = null;
        }
      });
    },
    async loadConsumerPortalLinkInfo({ commit, state }) {
      let consumerPortalLinkInfoData = getConsumerPortalLinkInfoData();
      if (
        consumerPortalLinkInfoData.workOrderId == state.workOrderId &&
        consumerPortalLinkInfoData.companyId == state.companyId
      ) {
        state.consumerPortalLinkInfo = consumerPortalLinkInfoData;
      } else {
        consumerPortalLinkInfoData = new ConsumerPortalLinkInfoViewModel(state.workOrderId, state.companyId);
        commit("setConsumerPortalLinkInfo", consumerPortalLinkInfoData);
      }
    },
    async loadElectronicSignature({ commit, state }) {
      if (state.consumerPortalLinkInfo.isElectronicSignature) {
        await ElectronicsSignatureService.getElectronicSignatureInfo(state.workOrderId, state.companyId).then(
          (result) => {
            commit("setElectronicSignature", result);
          }
        );
      }
    },
    async setupPaymentForWorkOrder({ commit, state }) {
      await PaymentGatewayService.getPaymentInfo(
        new PaymentInfoRequest(
          state.companyId,
          state.consumerPortalLinkInfo.paymentAmount,
          state.depositPercent,
          state.workOrder,
          state.isPayLater
        )
      ).then((result) => {
        commit("setPaymentInfo", result);
      });
    },
    async loadPaymentSettings({ commit, state }) {
      await PaymentGatewayService.getPaymentSettings(state.companyId).then((result) => {
        commit("setPaymentSettings", result);
      });
    },
    async loadAllWorkFlowData({ dispatch }): Promise<unknown> {
      const promisesToAwait = [] as Array<Promise<unknown>>;
      promisesToAwait.push(dispatch("loadWorkOrderInfo"));
      promisesToAwait.push(dispatch("loadPaymentSettings"));
      return Promise.all(promisesToAwait);
    },
  },
  getters: {
    getPendingPaymentsTotal: (state) => {
      let retVal = 0;
      if (state.fieldEdgePayments != null) {
        state.fieldEdgePayments.forEach((payment) => {
          if (payment.IsPendingPayment && parseFloat(payment.Amount)) {
            retVal += Number(payment.Amount);
          }
        });
      }
      return retVal;
    },
    getPaymentsForQuote: (state, getters) => (workOrder: WorkOrderModel) => {
      let retVal = 0;
      const quote = workOrder.Quote;
      if (getters.quoteAcceptedOrScheduled(quote)) {
        const paymentsForQuote = quote.Total - quote.Balance;
        retVal =
          paymentsForQuote > getters.getPendingPaymentsTotal ? paymentsForQuote - getters.getPendingPaymentsTotal : 0;
      }
      return retVal;
    },
    getRemainingBalance: (state, getters) => (workOrder: WorkOrderModel) => {
      return getters.invoiceAndAcceptedQuoteBalance(workOrder) + getters.getPendingPaymentsTotal;
    },
    getInvoiceBalance: (state, getters) => (workOrder: WorkOrderModel) => {
      const quote = workOrder.Quote;
      let invoiceBalanceWithoutPendingPayments = workOrder.Invoice.Balance;
      let pendingPaymentsTotal = getters.getPendingPaymentsTotal;
      if (pendingPaymentsTotal > 0) {
        if (getters.quoteAcceptedOrScheduled(quote) && quote.Balance < quote.Total) {
          pendingPaymentsTotal -= quote.Total - quote.Balance;
        }
        if (pendingPaymentsTotal > 0) {
          invoiceBalanceWithoutPendingPayments += pendingPaymentsTotal;
        }
      }
      return invoiceBalanceWithoutPendingPayments;
    },
    quoteAcceptedOrScheduled: () => (quote: QuoteModel) => {
      return quote != null && (quote.Status === eQuoteStatus.Accepted || quote.Status === eQuoteStatus.Scheduled);
    },
    doesQuoteExist: (state) => (workOrder: WorkOrderModel) => {
      return state.workflowType != eWorkflowType.InvoiceOnly && workOrder.Quote != null;
    },
    invoiceAndAcceptedQuoteBalance: (state, getters) => (workOrder: WorkOrderModel) => {
      let retVal = workOrder.Invoice.Balance;
      const quote = workOrder.Quote;
      if (getters.quoteAcceptedOrScheduled(quote)) {
        retVal += quote.Balance;
      }
      return retVal;
    },
    canNavigateToSummaryPage: (state, getters) => (workOrder: WorkOrderModel) => {
      let retVal = false;
      if (getters.getRemainingBalance(workOrder) > 0) {
        if (
          (state.workflowType == eWorkflowType.InvoiceOnly || state.workflowType == eWorkflowType.InvoiceAndQuote) &&
          getters.getInvoiceBalance(workOrder) > 0
        ) {
          retVal = true;
        } else if (
          (state.workflowType == eWorkflowType.QuoteOnly || state.workflowType == eWorkflowType.InvoiceAndQuote) &&
          workOrder.Quote.Total > 0
        ) {
          retVal = true;
        }
      }
      return retVal;
    },
    hasQuoteStatusAccepted: (state) => (quote: QuoteModel) => {
      const quoteOriginal = state.originalWorkOrder.Quote;

      const acceptedOriginalOption =
        quoteOriginal.Options != null ? quoteOriginal.Options.find((q) => q.Accepted) : new QuoteOptionModel();
      const acceptedOption = quote.Options != null ? quote.Options.find((q) => q.Accepted) : new QuoteOptionModel();

      const acceptedQuote =
        state.consumerPortalLinkInfo.isElectronicSignature == false &&
        state.consumerPortalLinkInfo.quoteSkipped == false &&
        state.consumerPortalLinkInfo.quoteDeclined == false &&
        quote.Status == eQuoteStatus.Accepted &&
        acceptedOriginalOption?.OptionID != acceptedOption?.OptionID;

      return acceptedQuote;
    },
    hasConsumerPortalLinkInfoStateChanged: (state) => (consumerPortalLinkInfo: ConsumerPortalLinkInfoViewModel) => {
      const consumerPortalLinkInfoOriginal = state.originalConsumerPortalLinkInfo;
      const consumerPortalLinkInfoState = consumerPortalLinkInfo;
      const hasStateChanged =
        consumerPortalLinkInfoState.payLater != consumerPortalLinkInfoOriginal.payLater ||
        consumerPortalLinkInfoState.quoteSkipped != consumerPortalLinkInfoOriginal.quoteSkipped ||
        consumerPortalLinkInfoState.quoteDeclined != consumerPortalLinkInfoOriginal.quoteDeclined ||
        consumerPortalLinkInfoState.paymentAmount != consumerPortalLinkInfoOriginal.paymentAmount ||
        consumerPortalLinkInfoState.quoteAccepted != consumerPortalLinkInfoOriginal.quoteAccepted ||
        JSON.stringify(consumerPortalLinkInfoState.quoteToAccept) !=
          JSON.stringify(consumerPortalLinkInfoOriginal.quoteToAccept);
      return hasStateChanged;
    },
  },
};
